import "../Assets/css/homepage/welcome.css"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import banner1 from "../Assets/images/banner1.jpg"
import banner2 from "../Assets/images/banner2.jpg"
import banner3 from "../Assets/images/banner3.jpg"
import banner1mob from "../Assets/images/banner1mob.png"
import banner2mob from "../Assets/images/banner2mob.jpg"
import banner3mob from "../Assets/images/banner3mob.jpg"

export default function Hero() {
  
  return (
    <>
      <div className="hero">
        <Carousel showArrows={true} autoPlay={true} infiniteLoop={true} interval={4000} swipeable={true}>
          <div>
            <img src={banner1} alt="banner"/>
          </div>
          <div>
            <img src={banner2} alt="banner" />
          </div>
          <div>
            <img src={banner3} alt="banner" />
          </div>
        </Carousel>
      </div>
      <div className="hero1">
        <Carousel showArrows={true} autoPlay={true} infiniteLoop={true} interval={4000} swipeable={false}>
          <div>
            <img src={banner1mob} alt="banner"/>
          </div>
          <div>
            <img src={banner2mob} alt="banner" />
          </div>
          <div>
            <img src={banner3mob} alt="banner" />
          </div>
        </Carousel>
      </div>
    </>
  );
}
