import React from "react";
import { Fade,Zoom } from "react-awesome-reveal";
import PropTypes from "prop-types";
import "../Assets/css/homepage/welcome.css"
import welcomejson from "../Assets/json/welcome.json"

const YoutubeEmbed = ({lg}) => (
  <> 
    <Fade direction="up" delay={200}>
      <div className="welcome_heading m-4">{welcomejson[0][lg][0].videoheading}</div>
    </Fade>
    
    <Zoom direction="up" delay={100}>
      <div className="video-responsive">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/vP8IiG17FQI?autoplay=1&loop=1&playlist=vP8IiG17FQI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </Zoom>
  </>
);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default YoutubeEmbed;