import { Fade,Slide } from "react-awesome-reveal";
import "../Assets/css/homepage/review.css"
import googlejson from "../Assets/json/google.json"
import welcomejson from "../Assets/json/welcome.json"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import user from "../Assets/images/user.png";
import five from "../Assets/images/5star.png";
import four from "../Assets/images/4star.png";
import three from "../Assets/images/3star.png";
import two from "../Assets/images/2star.png";
import one from "../Assets/images/1star.png";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

export default function Review({lg}) {
  var lang=googlejson[0][lg];
  return (
    <div className="review">
      <Fade direction="up" delay={200}>
        <div className="welcome_heading">{welcomejson[0][lg][0].google}</div>
      </Fade>
      
      <Slide direction="left" delay={200}>
        <Carousel responsive={responsive}>
          {lang && lang.map((item) =>{
            var sturl;
            var star=item.star;
            if(star===5){
              sturl=five;
            }
            else if(star===4){
              sturl=four;
            }
            else if(star===3){
              sturl=three;
            }
            else if(star===2){
              sturl=two;
            }
            else if(star===1){
              sturl=one;
            }
            return(<>
              <div className="review-cards m-3">
                <div className="d-flex review-card-lvl1">
                  <img src={user} alt="user" className="user"/>
                  <div className="review-name m-2">{item.name}</div>
                </div>
                <div className="d-flex justify-content-between review-card-lvl1 mt-1">
                  <img src={sturl} alt="star" className="star col-lg-8 col-sm-12"/>
                  <div className="review-date m-2">{item.date}</div>
                </div>
                <div className="d-flex justify-content-between review-card-lvl1 mt-3">
                  <div className="review-desc m-2">{item.text}</div>
                </div>
              </div>
            </>);
          })}
          
        </Carousel>
      </Slide>
      
    </div>
  );
}
