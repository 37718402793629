import "../Assets/css/general/navbar.css"
import logo from "../Assets/images/logo.png"
import menu from "../Assets/images/menu.png"
import navbarjson from "../Assets/json/navbar.json"

export default function Navbar({lg}) {
  function openNav() {
    document.getElementById("sidenav-menu").style.width = "100%";
  }

  function closeNav() {
    document.getElementById("sidenav-menu").style.width = "0";
  }
  return (
    <div className="navbar justify-space-between">
      <div className="d-flex">
        <img src={logo} alt="logo" className="logo"/>
        <div className="navbar_name">{navbarjson[0][lg][0].name1}<br/>{navbarjson[0][lg][0].name2}</div>
        <div className="navbar_name1">{navbarjson[0][lg][0].name1}{navbarjson[0][lg][0].name2}</div>
      </div>
      <div className="d-flex navmenu">
        <span><a href="/">{navbarjson[0][lg][0].menu1}</a></span>
        <span><a href="/products">{navbarjson[0][lg][0].menu3}</a></span>
        <span><a href="/insurance">{navbarjson[0][lg][0].menu4}</a></span>
        <span><a href="/finance">{navbarjson[0][lg][0].menu5}</a></span>
        <span><a href="/service">{navbarjson[0][lg][0].menu6}</a></span>
        <img src={menu} alt="menu" className="menu" onClick={openNav}/>
      </div>
      <div className="sidenav-menu" id="sidenav-menu">
        <div className="sidenav-close" onClick={closeNav}>X</div>
        <div className="sidenav-links justify-content-between">
          <a href="/">{navbarjson[0][lg][0].menu1}</a>
          <a href="/products">{navbarjson[0][lg][0].menu3}</a>
          <a href="/insurance">{navbarjson[0][lg][0].menu4}</a>
          <a href="/finance">{navbarjson[0][lg][0].menu5}</a>
          <a href="/service">{navbarjson[0][lg][0].menu6}</a>
        </div>
      </div>
    </div>
  );
}
