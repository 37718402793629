import "../Assets/css/general/footer.css"
import footerjson from "../Assets/json/footer.json"

export default function Footer({lg}) {
  return (
    <div className="footer">
      <div className="footer1 justify-content-between row">
        <div className="col-lg-3 col-sm-12">
          <div className="footer_head">{footerjson[0][lg][0].head2}</div>
          <div className="footer_body">
            <ul>
              <li><a href="https://www.google.com/maps/place/Sri+Ahobala+TVS/@14.2310649,76.4098838,15z/data=!4m2!3m1!1s0x0:0x13dbf68c382eb477?sa=X&ved=2ahUKEwjr2PLWwaT1AhVwT2wGHQoIBjQQ_BJ6BAgnEAU"
               target="_blank" rel="noreferrer">{footerjson[0][lg][0].address}</a></li>
               <li>{footerjson[0][lg][0].timing}</li>
            </ul>
          </div>
        </div>
    
        <div className="col-lg-3 col-sm-12">
          <div className="footer_head">{footerjson[0][lg][0].head1}</div>
          <div className="footer_body">
            <ul>
              <li><a href="/">{footerjson[0][lg][0].links[0].link1}</a></li>
              <li><a href="/service">{footerjson[0][lg][0].links[0].link2}</a></li>
              <li><a href="/product-enquiry">{footerjson[0][lg][0].links[0].link3}</a></li>
              <li><a href="/contact">{footerjson[0][lg][0].links[0].link4}</a></li>
              <li><a href="/policy">{footerjson[0][lg][0].links[0].link5}</a></li>
            </ul>
          </div>
        </div>
    
        <div className="col-lg-3 col-sm-12">
          <div className="footer_head">{footerjson[0][lg][0].head4}</div>
          <div className="footer_body">
            <ul>
              <li>{footerjson[0][lg][0].mobile}: <a href="tel:18001232280">1800 123 2280</a></li>
              <li>{footerjson[0][lg][0].mail}: <a href = "mailto: info@ahobalatvs.com">info@ahobalatvs.com</a></li>
            </ul>
          </div>
        </div>
        
        
      </div>
      <div className="copyright col-lg-12">
        Copyright © 2021 Sri Ahobala TVS. All rights received. Designed and Developed with ❤️ by DigiYogi Technologies.
      </div>
    </div>
  );
}
